import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCXHenIcbj-DGAy2jZM8Tzw6gB77RvQwog",
    authDomain: "wit-nagareru.firebaseapp.com",
    projectId: "wit-nagareru",
    storageBucket: "wit-nagareru.appspot.com",
    messagingSenderId: "853885571893",
    appId: "1:853885571893:web:0b100df4e3ba47f0b6327c"
  };
  
export const app = initializeApp(firebaseConfig);