import { Route, Routes } from 'react-router-dom';
import { VFC } from 'react';
import App from './App';
import Admin from "./admin"

const routes: VFC = () => {
    return (
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/admin-LzXW-aAQPrmisb9XDmfeMAjzAte-p89iCeHxFC7tm-DNTNtRPy" element={<Admin />} />
      </Routes>
    );
  };
  
  export default routes({});