import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from './firebase'; // firebaseConfig.tsをインポート
import './App.css'

const MyComponent: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(app);
      const snapshot = await getDocs(collection(db, 'text'));
      const items = snapshot.docs.map(doc => doc.data());
      setData(items);
    };

    fetchData();
  }, []);

  return (
    <div className='body'>
      <div className="back">
        <div className="text">
          {data.map((item, index) => (
            <a key={index}>{item.text}</a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyComponent;