import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { app } from './firebase';

const db = getFirestore(app);

const MyComponent: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [newText, setNewText] = useState<string>(''); // 新しいテキストを格納するstate

    useEffect(() => {
        const fetchData = async () => {
            const snapshot = await getDocs(collection(db, 'text'));
            const items = snapshot.docs.map(doc => doc.data());
            setData(items);
        };

        fetchData();
    }, []);

    // Firestoreに新しいテキストを書き込む関数
    const updateText = async () => {
        const docRef = doc(db, 'text', 'vW5YhvriwLxFjtiiOY6x'); // document_idは書き換えるドキュメントのIDに置き換えてください
        await setDoc(docRef, { text: newText }, { merge: true }); // mergeオプションを使用してデータを更新
        const snapshot = await getDocs(collection(db, 'text'));
        const items = snapshot.docs.map(doc => doc.data());
        setData(items);
    };

    return (
        <div>
            <ul>
                {data.map((item, index) => (
                    <li key={index}>{item.text}</li>
                ))}
            </ul>
            <input
                type="text"
                placeholder="新しいテキストを入力"
                value={newText}
                onChange={(e) => setNewText(e.target.value)} // 新しいテキストをstateに更新
            />
            <button onClick={updateText}>テキストを更新</button>
        </div>
    );
};

export default MyComponent;